import FilterCheckbox from '@/components/shared/FilterCheckbox';
import { useFiltersState } from '@/atoms/catalog/filters-state';
import { produce } from 'immer';
import { PresetFilterField, PresetFilterItem } from '@/types';
import ArrowMoreSVG from '@/svg/arrow-more.svg';
import RangeSlider from '@/components/shared/RangeSlider';
import { useEffect, useState } from 'react';
import Collapse from '@/components/shared/Collapse';
import { FILTER_COLLAPSE_DURATION } from './FiltersPopup';

let timer: NodeJS.Timeout | null;

interface Props {
    defaultValue: { min: number | null; max: number | null };
    stateMinValue?: number;
    stateMaxValue?: number;
    presetFilters: PresetFilterItem[];
    type: PresetFilterField;
    label: string;
    subLabel: string;
    onUpdate: (...args: any) => void;
    onScroll: (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
    prefix?: string;
    suffix?: string;
    step?: number;
}

const filterMapping: Record<
    PresetFilterField,
    {
        min: 'priceMin' | 'diameterMin' | 'powerMin';

        max: 'priceMax' | 'diameterMax' | 'powerMax';
    }
> = {
    price: {
        min: 'priceMin',
        max: 'priceMax',
    },
    diameter: {
        min: 'diameterMin',
        max: 'diameterMax',
    },
    power: {
        min: 'powerMin',
        max: 'powerMax',
    },
};

const DoubleFiltersItem = ({
    defaultValue,
    stateMinValue,
    stateMaxValue,
    presetFilters,
    label,
    subLabel,
    type,
    onScroll,
    onUpdate,
    prefix,
    suffix,
    step = 10,
}: Props) => {
    const [, setFiltersState] = useFiltersState();
    const [isOpened, setIsOpened] = useState(false);
    const [currentValue, setCurrentValue] = useState<{ minValue: number | null; maxValue: number | null }>({
        minValue: stateMinValue ?? null,
        maxValue: stateMaxValue ?? null,
    });

    useEffect(() => {
        if (stateMinValue !== undefined && stateMaxValue !== undefined) {
            setCurrentValue({
                minValue: stateMinValue,
                maxValue: stateMaxValue,
            });
        }
    }, [stateMaxValue, stateMinValue]);

    const onClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        isOpened
            ? timer
                ? (clearTimeout(timer), (timer = null))
                : (timer = setTimeout(() => {
                      setIsOpened(false);
                      timer = null;
                  }, FILTER_COLLAPSE_DURATION))
            : setIsOpened(true);

        onScroll(event);
    };

    return (
        <>
            <Collapse collapsed>
                <div className="input-group">
                    <label className="filter-row__name js-filter-row-name m-text-xs">{label}</label>
                    <Collapse.Toggler
                        className="select-toggler js-select-toggler-first"
                        onToggle={onClickHandler}
                        data-dropdown-group={type}
                    >
                        <ArrowMoreSVG />
                    </Collapse.Toggler>
                </div>
                <Collapse.Content>
                    <div className="filter-row">
                        <div className="filter-row__list">
                            <RangeSlider
                                min={defaultValue.min ?? 0}
                                max={defaultValue.max ?? 0}
                                currentMin={currentValue.minValue ?? stateMinValue}
                                currentMax={currentValue.maxValue ?? stateMaxValue}
                                step={step}
                                prefix={prefix}
                                suffix={suffix}
                                onChange={onUpdate}
                            />
                        </div>
                    </div>
                </Collapse.Content>
            </Collapse>
            {presetFilters.length > 0 && (
                <Collapse collapsed>
                    <div className="input-group">
                        <label className="filter-row__name js-filter-row-name m-text-xs">{subLabel}</label>
                        <Collapse.Toggler
                            className="select-toggler js-select-toggler-second"
                            onToggle={onClickHandler}
                            data-dropdown-group={type}
                        >
                            <ArrowMoreSVG />
                        </Collapse.Toggler>
                    </div>
                    <Collapse.Content>
                        {isOpened && (
                            <div className="filter-row">
                                <div className="filter-row__list">
                                    {presetFilters.map((filter, i) => {
                                        const valueMin = filter.min ?? defaultValue.min;
                                        const valueMax = filter.max ?? defaultValue.max;

                                        const isChecked = stateMinValue === valueMin && stateMaxValue === valueMax;

                                        return (
                                            <FilterCheckbox
                                                key={i}
                                                name={filter.name}
                                                value={i.toString()}
                                                checked={isChecked}
                                                onChange={(arg) => {
                                                    isChecked
                                                        ? setCurrentValue({
                                                              minValue: defaultValue.min,
                                                              maxValue: defaultValue.max,
                                                          })
                                                        : setCurrentValue({
                                                              minValue: valueMin,
                                                              maxValue: valueMax,
                                                          });
                                                    setFiltersState(
                                                        produce((draft) => {
                                                            if (filterMapping[type]) {
                                                                const { min, max } = filterMapping[type];
                                                                if (arg) {
                                                                    draft[min] = valueMin ?? undefined;
                                                                    draft[max] = valueMax ?? undefined;
                                                                } else {
                                                                    draft[min] = undefined;
                                                                    draft[max] = undefined;
                                                                }
                                                            }
                                                        }),
                                                    );
                                                }}
                                            >
                                                {filter.name}
                                            </FilterCheckbox>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </Collapse.Content>
                </Collapse>
            )}
        </>
    );
};

export default DoubleFiltersItem;
