import { toFormData } from '@/utils/forms';
import axios from 'axios';

export const CALLTOUCH_ID = 'e8yvpz5v';
export const CALLTOUCH_WEBSITE_ID = '69480';

export const sendDataToCalltouch = (data: Record<string, unknown>) => {
    axios.post(
        `https://api.calltouch.ru/calls-service/RestAPI/requests/${CALLTOUCH_WEBSITE_ID}/register/`,
        toFormData({
            ...data,
            requestUrl: location.href,
            sessionId: (window as any).ct?.('calltracking_params', CALLTOUCH_ID).sessionId,
        }),
    );
};
